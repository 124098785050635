
// import TableList from "./TableList.vue";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
import NotPermission from "@/views/Errors/not_permission.vue";
import Buttons from "../../Commons/Buttons.vue";
import SnackBar from "../../Commons/SnackBar.vue";
import Vue from "vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import omit from 'lodash/omit';
import {
	Campaign,
	CampaingFilters,
	ResultPaginate,
} from "../../../../interfaces/campaign";
import ParamService from "../../../../services/params-service";
import { Paginated, SortingOption } from "../../../../interfaces/paginated";
import { mapActions, mapGetters } from "vuex";
import {
	prepareSelectedData,
	prepareTableData,
	prepareTableContent,
	preparedColumnDefsGrid
} from "../../../../utils/CustomizeViewData";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import CloneEntity from "../../Commons/DuplicatedEntity.vue";
// @ts-ignore
import { MessageTypes } from "@/interfaces/proccess";
// @ts-ignore
import ActivityLog from "../../Commons/ActivityLog.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue"
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue"
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue"
// @ts-ignore
import CustomizeRowGrid from "@/components/Header/Tables/CustomizeRowGrid.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import ConfirmDelete from "@/views/Admin/Commons/ConfirmDelete.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
import BulkEntity from "@/models/Bulk";
//@ts-ignore
import { getPermisionCampaign } from "@/utils/permissionResolve";
import SurveyDialog from "@/components/Commons/Survey/SurveyDialog.vue";

export default Vue.extend({
	name: "CampaignList",
	props: {},
	components: {
		Buttons,
		SnackBar,
		Dialog,
		CloneEntity,
		ActivityLog,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		CustomizeRowGrid,
		ConfirmDelete,
		NotPermission,
		SurveyDialog
	},
	data: () => ({
		title: "List",
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption(),
		table: {
			headers: [],
			items: [],
		},
		isClone: false,
		fields: {},
		entityDuplicated: undefined,
		optionsDuplicated: {},
		openDuplicated: false,
		selectedIdsEdit: [],
		bulk: new BulkEntity(true),
		columnRestored: false,
		context: null,
		selectionRows: "multiple",
		optionDelete:{
			id:null,
			open:false,
			err: false,
			entity: "Campaign",
			msg: "",
			sub_msg:""
		}
	}),
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {
		if (this.hasAdvertiserID()) {
			this.filters.advertiser_id = this.getAdvertiserID();
			await this.resetFilter();
			await this.saveFilters();
		}
		await this.verifyFilters();
		await this.getPaginated();
		this.context = { componentParent: this };
	},
	computed: {
		...mapGetters("profile", ["account", "getAbility"]),
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("profile", ["isRolReport"]),
		...mapGetters("customizer_filter", ["getFiltersEntity"]),
		...mapGetters("breadcrumbsM", [
			"getNavegationData",
		]),
		...mapGetters("internationalization", ["getLanguage"]),

		getTo(){
			return this.getNavegationData("to");
		},

		isReadOnly(): Boolean {
			return this.isRolReport;
		},

		getAccount() {
			return this.account;
		},

		getResultPaginate(): ResultPaginate {
			return this.$store.state.campaign.result_paginate;
		},

		getCampaigns(): Campaign[] {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

		getSizeCampaign() {
			return this.$t("show.campaigns", {
				total: this.getCampaigns.length,
			});
		},

		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},

		getConfigColumnDef(){
			return {
				context: this.context,
				eventActive: true,
				entityName: "Campaign",
				redirect: "CampaignEdit",
				activityLog: "ActivityLogCampaing",
				edit: true,
				duplicated: this.isReadOnly ? false : true,
				log: this.isReadOnly ? false : true,
				list: true,
				flex: 0,
				readonly: this.isReadOnly,
				redirecCustom: { to: "CampaignEdit", isColumn: true, parameter: "params", isInvoke: false },
				minWidthActions: 200,
				maxWidthActions: 200,
				deleteAction:true,
				headerCheckboxSelection: this.canCheck,
				checkboxSelection: this.canCheck,
				permission: this.getPermission
			};
		},

		getFiltrosAplicados() {
			const newFilters = this.getFiltersEntity("CampaignFilter").filters;
			return Object.keys(newFilters).length > 0 ? newFilters : {};
		},

		getOptionsAplicados() {
			return this.getFiltersEntity("CampaignFilter").options;
		},

		getColumnsDef(){
			return preparedColumnDefsGrid(this.table.headers, this.getConfigColumnDef);
        },

        gerRowsData(){
            if(isEmpty(this.table.items)){
                return [];
            }else{
				return this.table.items;
			}
        },

		canCheck(){
			return this.getAbility.can(this.getPermission.actions.active, this.getPermission.subject);
		},

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionCampaign();
		}
	},
	async destroyed(){
		await this.resetFilter();
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		/** Begin Customize View **/

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected"
		]),

		...mapActions("proccess", [
			"setNotification",
		]),

		...mapActions("campaign", ["setActive", "deleteCampaignByID"]),

		...mapActions("customizer_filter", [
			"saveFiltersEntity",
			"resetFilterEntity",
		]),

		...mapActions("clone_entity", ["fetchCloneEntity"]),

		async verifyFilters() {
			const newFilters = this.getFiltrosAplicados;
			if (Object.keys(newFilters).length < 1) return;
			this.filters = newFilters;
			this.options = this.getOptionsAplicados;
		},

		hasAdvertiserID() {
			if (isEmpty(this.$route.query)) {
				return false;
			} else {
				return this.$route.query.advertiser_id > 0;
			}
		},
		getAdvertiserID() {
			return this.$route.query.advertiser_id;
		},

		openDialogDuplicated(params: any) {
			this.entityDuplicated = params.id;
			this.getOptionsDuplicated(params.id);
			this.openDuplicated = true;
		},

		closeDialogDuplicated() {
			this.entityDuplicated = undefined;
			this.openDuplicated = false;
		},

		getOptionsDuplicated(id: number) {
			this.optionsDuplicated = this.getCampaigns.find(
				(c) => c.id == id
			).clonable;
		},

		getMsgError(redirec: boolean, to: string, message: string) {
			let msgSucces = {
				title: "Error",
				message: message,
				type: MessageTypes.ERROR,
				show: true,
				details: "",
				to: redirec ? to : undefined,
				btn_text: "Close",
			};
			return msgSucces;
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach(element => {
				let col = this.table.headers.filter(c => c.value == element.col);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async handleDuplicated(params: any) {
			this.isClone = true;
			await this.fetchCloneEntity({
				key: "campaign",
				data: params,
			}).catch((err) => {
				this.isClone = false;
				this.closeDialogDuplicated();
				this.setNotification(this.getMsgError(false, "", err.message));
			});
			this.isClone = false;
			this.closeDialogDuplicated();
			await this.updateParams({
				filters: this.filters,
				options: this.options,
			});
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async updateItemsTable(){
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			if(!isEmpty(selected)){
				this.fields = await prepareSelectedData(selected);

				this.table.items = await prepareTableContent(this.fields, this.getCampaigns);
				this.$forceUpdate();
			}
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getCampaigns,
			});

			this.fields = fields;
			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();
			
			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "campaign",
				entities: this.getCampaigns,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		/** End Customize View **/

		async getPaginated(uTable: boolean = true) {
			if(this.canList){
				await this.setLoadingData(TypeLoading.loading);
				await this.$store.dispatch(
					"campaign/paginated",
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				if(uTable){
					await this.getTableData({ view: this.$route?.name });
				}else{
					await this.updateItemsTable();
				}
				await this.setLoadingData();
			}
		},
		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
			await this.saveFilters();
		},
		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options,
			}, false);
		},
		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async updateParams(params: {
			filters: CampaingFilters;
			options: SortingOption;
		}, uTable: boolean = true) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},
		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			await this.resetFilter();
			this.updatePaginate(1);
			await this.getPaginated(false);
		},

		async handleActive(event: any) {
			await this.setLoadingData(event.active ? TypeLoading.enable : TypeLoading.disable);
			
			const reponse = await this.setActive({
				id: event.id,
				active: event.active,
			}).catch(async (error: any) => {
				this.setUpdateLineItem(event.id, true, !event.active, event.rowIndex); 
				await this.setLoadingData();
			});
			this.setUpdateLineItem(event.id, false, reponse, null);
			await this.setLoadingData();
		},

		setUpdateLineItem(id: number, err: boolean, entity: any, rowIndex: any) {
			if(err){
				this.$refs.Grid_Line.updateRowNodeByIndex(rowIndex, entity);
			}else{
				this.table.items.find((v: { id: number }) => v.id == id).active = entity.active;
			}
		},

		async saveFilters() {
			this.saveFiltersEntity({
				view: "CampaignFilter",
				filters: { filters: this.filters, options: this.options },
			});
		},
		async resetFilter() {
			this.resetFilterEntity({
				view: "CampaignFilter",
				filters: { filters: {}, options: {} },
			});
		},
		async removeKeyFilter(key: string) {
			const newFilters: any = omit(this.filters, [key]);
			await this.updateParams({
				filters: newFilters,
				options: this.options,
			}, false);
			await this.saveFilters();
		},

		handleSelectedIds(params: Array<any>) {
			this.bulk.selected = params;
		},

		handleBulk(bulk: BulkEntity) {
			this.bulk = bulk;
			this.updateItemsTable();
		},

		handleActiveBulk() {
			// console.log("Lines::handleActiveBulk", {
			// 	lines: this.bulk.selected,
			// });
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},

		methodFromParent(cell) {
			alert('Parent Component Method from ' + cell + '!');
		},
		async updateIndex(){
			if (this.hasAdvertiserID()) {
				this.filters.advertiser_id = this.getAdvertiserID();
				await this.saveFilters();
				this.getPaginated(false);
			}else{
				await this.removeKeyFilter("advertiser_id");
			}
		},

		async handleRedirectMethod(params: any, newWindow: boolean = true){
			if(params.column == "advertiser_name"){
				let campaign = this.getCampaigns.find((i) => i.id == params.id);
				if(!isNull(campaign)){
					let obj = {
						name: "AdvertiserEdit",
						params: {
							id: campaign.advertiser_id
						}
					}

					/**
					 * Open in a new windows just when is Ctrl + Click
					 */
					if(newWindow) {
						const routeData = this.$router.resolve(obj);
						window.open(routeData.href, '_blank');
					} else {
						this.$router.push(obj);
					}
				}
			}
		},
		async handlerCancelDelete(){
			this.optionDelete.open = false;
			this.optionDelete.id = null;
		},

		async handlerDeleteCampaignByID(id: Number){
			await this.setLoadingData(TypeLoading.loading);
			await this.deleteCampaignByID(id)
			.then(async (result) => {
				await this.handlerCancelDelete();
				await this.setLoadingData();
				await this.getPaginated(false);
			}).catch(async (err) => {
				await this.setLoadingData();
				await this.handlerCancelDelete();
			});
		},

		async deleteByID(params: any){
			this.optionDelete.open = true;
			this.optionDelete.id = params.id;
			this.optionDelete.err = false;

			let msg = `${i18n.t("delete_msg.campaign.msg_type_one")} ${params.name} (ID: ${params.ext_id})?`

			this.optionDelete.msg = msg;
			this.optionDelete.sub_msg = i18n.t("delete_msg.campaign.sub_msg");

			/*
			let campaignData = this.getCampaigns.find(c => c.id == params.id);
			if(!isNull(campaignData)){
				if(campaignData.line_items_count > 0){
					this.optionDelete.err = true;
					this.optionDelete.sub_msg = "";
					this.optionDelete.msg = i18n.t("delete_msg.campaign.msg_type_two");
				}
				if(Number(campaignData.spend) > 0){
					this.optionDelete.err = true;
					this.optionDelete.sub_msg = "";
					this.optionDelete.msg = i18n.t("delete_msg.campaign.msg_type_three");
				}
			}*/
		}
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated(false);
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
		"account"(val, old){
			if (val !== old) {
				this.getPaginated(true);
			}
		},
		"getTo"(val, old){
			this.updateIndex();
		}
	},
});
