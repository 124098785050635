

import Vue from "vue";
// @ts-ignore
import Filterable from "@/components/Header/Tables/Filterable.vue";
// @ts-ignore
import { isEmpty, isUndefined, isNaN } from "lodash";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { mapActions } from "vuex";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "ActivityLog",
	props: {},
	components: {
		Filterable
	},
	data: () => ({
		flatPanels: false,
		tilePanels:false,
		pathBread: [],
		items: [],
		headers: [],
		table:{
			current_page: 0,
			next_page_url: 0,
			path: 0,
			per_page: -1,
			prev_page_url: 0,
			to: 0,
			total: 0
		},
		params:{
			filter:{
				options: getDefaultSortingOption("id"),
				filters: {},
			},
			entity:{
				id: undefined,
				entityName: undefined
			}
		},
	}),
	created() {
		this.$nextTick(async () => {
			await this.setEntity();
		});
	},
	mounted() {
		this.$nextTick(async () => {
			await this.loadResources();
		});
	},
	computed: {},
	methods: {

		...mapActions("activity_log", ["all"]),

		...mapActions("loading", ["setLoadingData"]),

		getID(): Number {
			return Number(this.$route.query.id);
		},

		hasID() {
			return !!this.$route.query.id;
		},

		getEntity(): String {
			return this.matchedRoutes()[this.$route?.name];
		},

		getDataItems(item: any){
			let response = new Array();
			for(const [key, value] of Object.entries(item)){
				let entity = new Array();
				entity[0] = key;
				entity[1] = value;
				response.push(entity);
			}
			return response;
		},

		getHeightItemPanel(count: number){
			if(!count) return "10";
			if(count > 10){
				return (count * 30) / 2;
			}else{
				return (count * 30);
			}
		},

		isCreative(entity: string = ""){
			return entity == "creative";
		},

		matchedRoutes(){
			return {
				ActivityLogCreative: "creative",
				ActivityLogLineItem: "lineItem",
				ActivityLogCampaing: "campaign",
				ActivityLogAdvertiser: "advertiser"
			};
		},

		async setEntity(){
			this.params.entity.id = this.getID();
			this.params.entity.entityName = this.getEntity();
		},

		async setBreadCrum(){
			let t = this.params.entity.entityName + " : Id " +  this.params.entity.id;
			this.pathBread.push({
				text: t, 
				data: this.items, 
				headerC: this.isCreative(this.getEntity()),
				primary: true
			});
		},

		async loadResources(){
			this.headers = await this.prepareTableHeaders(this.isCreative(this.getEntity()));
			if(!isUndefined(this.params.entity.entityName) && !isNaN(this.params.entity.id)){
				await this.setLoadingData(TypeLoading.loading);
				this.items = await this.all(this.params);
				await this.setLoadingData();
				await this.setBreadCrum();
			}
		},

		async loadItems(item: any){
			
			await this.setLoadingData(TypeLoading.loading);
			this.headers = await this.prepareTableHeaders(item.headerC);
			this.items = item.data;
			if(item.primary){
				let i = item;
				this.pathBread = [];
				this.pathBread.push(i);
			}
			await this.setLoadingData();
			
		},	

		async prepareTableHeaders(entity: boolean = false){
			let headers = [
				{
					text: "ID",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "id",
					width: "50px"
				},
				{
					text: "Event",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "event",
					width: "80px"
				},
				{
					text: "Values",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "new_values",
					width: "300px"
				},
				{
					text: "User Name",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "user_name",
					width: "150px"
				},
				{
					text: "Created Date",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "created_date",
					width: "100px"
				},
				{
					text: "Updated Date",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "updated_date",
					width: "100px"
				},
			];
			if(entity){
				const headerCreative = await this.prepareHeaderCreative();
				headerCreative.forEach(h => {
					headers.push(h);
				});
			}
			return headers;
		},

		async prepareHeaderCreative(){
			return [
				{
					text: "Creative Ad Content",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "creativeAdContent",
					width: "70px"
				},
				{
					text: "Creative Addon Settings",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "creativeAddonSettings",
					width: "70px"
				},
				{
					text: "Creative Advertiser",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "creativeAdvertiser",
					width: "70px"
				},
				{
					text: "Creative Attributes",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "creativeAttributes",
					width: "70px"
				},
				{
					text: "Creative Exchange Options",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "creativeExchangeOptions",
					width: "70px"
				},
				{
					text: "Line Associations",
					align: "center",
					sortable: false,
					api_sortable: false,
					filterable: true,
					value: "lineAssociations",
					width: "70px"
				},
			]
		},

		async prepareItemsCreatives(items: any, t: string, p: boolean = false){
			
			await this.setLoadingData(TypeLoading.loading);
			this.headers = await this.prepareTableHeaders();
			this.items = items;
			this.pathBread.push({
				text: t, 
				data: this.items, 
				headerC: this.isCreative(t),
				primary: p
			});
			await this.setLoadingData();
			
		},

		async prepareItemsCreativesLineAssociation(items: any, t: string, p: boolean = false){
			
			await this.setLoadingData(TypeLoading.loading);
			this.headers = await this.prepareTableHeaders();
			this.items = [];
			for(const [key, value] of Object.entries(items)){
				items[key].forEach(o => {
					this.items.push(o);
				});
			}
			this.pathBread.push({
				text: t, 
				data: this.items, 
				headerC: this.isCreative(t),
				primary: p
			});
			await this.setLoadingData();
			
		}

	},
	watch: {},
});
